.wrap-icon-sugerencias {
    float: right;
    position: fixed;
    bottom: 5em;
    display: flex;
    align-items: center;
    text-decoration: none;
    right: -200px;
    transition: all .8s;
    overflow: hidden;
}

.wrap-icon-sugerencias:hover {
    right: 1px;
    transition: all .8s;
}

.wrap-icon-sugerencias img {
    height: 4em;
    display: inline-block;
}

.wrap-sugerencias-label {
    display: inline-block;
    color: #FFF;
    background-color: #6098D0;
    font-style: italic;
    padding: 0 1em 0 1.7em;
    margin-left: -1em;
    z-index: -9999;
    width: 220px;
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

.animated {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes bounceInRight {

    60%,
    75%,
    90%,
    0%,
    100% {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}