table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
  color: #203764;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  vertical-align: top;
}

th {
  background-color: #243e63;
  color: white;
  text-align: center;
}

.jurisdiccion {
  width: 15%;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.descripcion-principal {
  width: 20%;
  background-color: #ddebf7;
  vertical-align: middle;
  font-weight: bold;
}

.descripcion-detalle {
  width: 25%;
  background-color: #f8f9fa;
}

.canales {
  width: 20%;
}

.tiempo {
  width: 20%;
  text-align: center;
}

.titulo-importacion {
  font-weight: bold;
  margin-bottom: 8px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  /* Convierte la tabla en formato "card" */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Oculta las cabeceras en vista móvil */
  thead tr {
    display: none;
  }

  /* Estilo de cada fila para verse como una tarjeta */
  tr {
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;
  }

  /* Celdas se muestran como listas */
  td {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    text-align: left;
    position: relative;
  }

  /* Etiquetas con data-label */
  td:before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
  }

  /* Ajustes para mejorar la visualización en móvil */
  .jurisdiccion,
  .descripcion-principal,
  .descripcion-detalle,
  .canales,
  .tiempo {
    width: 100%;
    display: block;
    text-align: left;
  }
}
